<template>
	<div>
		<div class="flex header">
			<h2>用户管理</h2>
			<div class="flex">
				<el-input
						placeholder="请输入关键信息"
						prefix-icon="el-icon-search"
						v-model="search"
						@change="getData">
				</el-input>
				<el-button @click="addUserCl">新增用户</el-button>
			</div>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 248px)"
					style="width: 100%">
				<el-table-column
						prop="name" align="center" show-overflow-tooltip
						label="姓名">
				</el-table-column>
				<el-table-column
						prop="roleNames" align="center" show-overflow-tooltip
						label="角色">
				</el-table-column>
				<el-table-column
						prop="tel" align="center" show-overflow-tooltip
						label="手机">
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="部门">
					<template slot-scope="scope">
						{{ scope.row.depart ? scope.row.depart : "-" }}
					</template>
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="状态">
					<template slot-scope="scope">
						<el-switch
								v-model="scope.row.state"
								active-color="#0067A6"
								inactive-color="#DCDFE6"
								:active-value="1"
								:inactive-value="0"
								@change="changState(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" fixed="right"
												 label="操作" width="280px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleUpdatePass(scope.row.id)">密码修改</el-button>
						<el-button class="update" @click="handleEdit(scope.$index, scope.row)">信息修改</el-button>
						<el-button class="update" @click="handleDel(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--		用户信息-->
		<userAdd :state="title" :showEditUser="showEditUser" :userInfo="userInfo" @openUser="editUser" :roleList="roleList" :roleName="roleName"></userAdd>
		<!--密码-->
		<el-dialog
				top="0" class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="dialogPwd" :show-close="false"
				width="400px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">修改密码</h1>
			</template>
			<el-form label-position="top" :rules="rules" ref="formPwd" class="rowsInfo" label-suffix="：" label-width="80px"
							 :model="ruleForm">
				<el-form-item label="新密码">
					<el-input type="password" v-model="ruleForm.password" clearable placeholder="请输入新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码">
					<el-input type="password" v-model="ruleForm.confirmPass" clearable placeholder="请输入新密码"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogPwd=false">取 消</el-button>
				<el-button type="primary" @click="submitPwd('formPwd')">确 定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
import { updateAuthUserPassWord, getAuthUserList, deleteAuthUser, getAuthRoleList,updateAuthUser} from "@/api/api";

export default {
	name: "userMana",
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				if (this.ruleForm.confirmPass !== "") {
					this.$refs.ruleForm.validateField("confirmPass");
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.ruleForm.password) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			search: "",
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			showEditUser: false,
			title: "新增",
			userInfo: {
				name: "",
				roleNames: "",
				tel: "",
				depart: ""
			},
			dialogPwd: false,
			ruleForm: {
				password: "",
				confirmPass: "",
				userId:""
			},
			rules: {
				password: [
					{ validator: validatePass, trigger: "blur" }
				],
				confirmPass: [
					{ validator: validatePass2, trigger: "blur" }
				]
			},
			roleList:[],
			roleName:''
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		changState(row){
			updateAuthUser(JSON.stringify(row)).then((res) => {
				this.$message.success(res.data.msg);
			}).catch(() => {
				this.$message.error("修改失败");
			});
		},
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size,
				key: this.search
			};
			getAuthUserList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});
		},

		// 切换page
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		// 新增
		addUserCl() {
			this.showEditUser = true;
			this.getRoleList()
			this.title = "新增";
			this.userInfo = {
				name: "",
				roleNames: "",
				tel: "",
				depart: ""
			};
			this.roleName=''
		},
		//修改密码
		handleUpdatePass(id){
			this.dialogPwd = true
			this.ruleForm.userId = id
		},
		// 编辑用户
		handleEdit(index, row) {
			this.showEditUser = true;
			this.getRoleList()
			this.title = "编辑";
			this.userInfo = row;
			this.roleName = this.userInfo.roleNames
		},
		// 关闭弹窗
		editUser(obj) {
			this.showEditUser = false;
			if (!obj.flg) {
				this.getData();
			}
		},
		submitPwd(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					updateAuthUserPassWord(JSON.stringify(this.ruleForm)).then(res=>{
						if (res.data.code==200){
							this.$message.success("修改成功")
							this.dialogPwd = false
						} else {
							this.$message.error(res.data.msg)
						}
					})
				} else {
					return false;
				}
			});
		},
		// 删除
		handleDel(id) {
			this.$confirm("此操作将永久删除用户，是否继续?", "提示", { type: "warning" })
					.then(() => { // 向服务端请求删除
						deleteAuthUser(id).then(res => {
							this.$message.success(res.data.msg);
							this.getData();
						}).catch(() => {
							this.$message.error("删除失败!");
						});
					}).catch(() => {
				this.$message.info("已取消操作!");
			});
		},
		//查询角色
		getRoleList(){
			let param={
				page: 1,
				size: 9999
			}
			getAuthRoleList(JSON.stringify(param)).then(res=>{
				this.roleList = res.data.rows
			})
		},
		/*initWebSocket(){
			let url = "ws://192.168.1.18:8019/ws/lyh";
			let socket = new WebSocket(url);

			socket.onopen = function () {
				console.log('opened....');
			}
			socket.onmessage = function (e) {
				console.log(e);
				let msg = e.data;
				console.log(msg);
				if(msg === 'ok'){
					location.href = 'https://baidu.com';
				}
			}
			socket.onerror = function (e) {
				console.error('err: ==>');
				console.error(e);
			}

		}*/
	},
	components: {
		"userAdd": () => import("./userAdd")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
